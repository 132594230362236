interface AnyQuestion {
  type: string;
  name: string;
  title: any;
  valueName: string;
  isRequired: boolean;
}

interface SurveyDef {
  _id: string;
  pages: {
    elements: Array<AnyQuestion>;
  }
}

interface LoadSurveyResponse {
  answers: {
    _id: string;
  };
  survey: {
    config: SurveyDef;
  };
}

export interface SurveyConfig {
  answerId?: string;
  surveyDef?: SurveyDef;
}

interface ServerError {
  error?: string;
  message?: string;
  statusCode?: number;
}

class ResponseService {

  baseUrl = 'https://api.clip.cx-mate.com/api';

  loadSurveyDef = async (invitationToken: string): Promise<SurveyConfig> => {
    const fetchOpts: RequestInit = {
      method: 'GET',
    };
    let URL = `${this.baseUrl}/survey-engine/answers/token/${invitationToken}`;
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    if (fetchResponse.status >= 400) {
      let errorMessage = 'Ocurrió un problema al cargar la encuesta';
      const errorDetails: ServerError = await fetchResponse.json();
      if (errorDetails.error === 'Bad Request' && errorDetails.message) {
        errorMessage = errorDetails.message;
      }
      throw new Error(errorMessage);
    }
    const { answers, survey }: LoadSurveyResponse = await fetchResponse.json();
    return {
      answerId: answers._id,
      surveyDef: survey.config,
    };
  };

  formatResponse = (response: any): any => {
    let multipleQuestions = [];
    let finalResponse: any = {};
    for (const k in response) {
      if (Array.isArray(response[k])) {
        multipleQuestions.push(k);
      } else {
        finalResponse[k] = response[k];
      }
    }
    multipleQuestions.forEach(k => {
      response[k].forEach((answerId: string) => {
        finalResponse[`${k}_${answerId}`] = true;
      });
    })
    return finalResponse;
  }

  saveResponse = async (responseId: string, response: any): Promise<any> => {
    const formattedResponse = this.formatResponse(response);
    console.log('Saving responses', formattedResponse);
    const body = JSON.stringify(formattedResponse);
    const fetchOpts: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    };
    let URL = `${this.baseUrl}/survey-engine/answers/${responseId}`;
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    const updateResponse = await fetchResponse.json();
    return updateResponse;
  };

}

export default new ResponseService();