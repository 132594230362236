import React from 'react';
import { registeredSurveys } from '../../surveys';

export function SurveyIndex () {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <h2>Encuestas disponibles</h2>
      {/* <p style={{ textAlign: 'center' }}><small>Solo para fines demostrativos, esta sección no es visible durante el levantamiento.</small></p> */}
      {
        registeredSurveys.map(s => (
          <a
            href={`/test/${s.id}`}
            target="_blank"
            style={{ fontSize: '20px', marginBottom: '1em', textAlign: 'center' }}
          >
            {s.label}
          </a>
        ))
      }
    </div>
  )
}