import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivacyPolicy } from './legal';
import { Survey, Thanks, Welcome, SurveyIndex, TestSurvey } from './survey';

function App() {
  return (
    <>
      <header className="survey-header">
        <img src="/brand/brand-logo.png" height="80" alt="" />
      </header>
      <main className="survey-main">
        <div className="container">
          <Switch>
            <Route exact path="/aviso-de-privacidad" component={PrivacyPolicy} />
            <Route exact path="/gracias" component={Thanks} />
            <Route exact path="/test-mode" component={SurveyIndex} />
            <Route exact path="/:id" component={Survey} />
            <Route exact path="/test/:id" component={TestSurvey} />
            <Route exact path="/" component={Welcome} />
          </Switch>
        </div>
      </main>
    </>
  );
}

export default App;