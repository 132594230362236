import demoSC from './demo-survey.json';
import cacxAgenteInicioSC from './cacx-agente-inicio.json';
import cacxAgenteDuranteSC from './cacx-agente-durante.json';
import cacxAgentePostSC from './cacx-agente-post.json';
import cacxAgenteAbandonadorSC from './cacx-agente-abandonador.json';
import cacxEmpresaAccessSC from './cacx-empresa-access.json';
import cacxEmpresaExchangeSC from './cacx-empresa-exchange.json';
import cacxEmpresaMomentumSC from './cacx-empresa-momentum.json';
import cacxEmpresaLostSC from './cacx-empresa-lost.json';
import cacxEmpresaKamSC from './cacx-empresa-kam.json';
import eacxEntregaUnidadSC from './eacx-entrega-unidad.json';

export {
  demoSC,
  cacxAgenteInicioSC,
  cacxAgenteDuranteSC,
  cacxAgentePostSC,
  cacxAgenteAbandonadorSC,
  cacxEmpresaAccessSC,
  cacxEmpresaExchangeSC,
  cacxEmpresaMomentumSC,
  cacxEmpresaLostSC,
  cacxEmpresaKamSC,
  eacxEntregaUnidadSC,
}

export const registeredSurveys = [
  {
    id: 'eacxEntregaUnidadSC',
    label: 'EIDOS - Entrega de unidad',
    config: eacxEntregaUnidadSC,
  }
]