import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as SurveyJS from 'survey-react';
import surveyClasses from './surveyjs-classes.json';
import ResponseService, { SurveyConfig } from '../../services/response';

SurveyJS.surveyLocalization.locales['es'] = Object.assign(SurveyJS.surveyLocalization.locales['es'], {
  requiredError: "Por favor responde la pregunta.",
  otherRequiredError: "Por favor agregue el valor de Otro.",
});

const setRatingClasses = (survey: any, options: any) => {
  if (options.question.getType() !== 'rating') return;
  if (options.question.rateMax === 10) {
    options.cssClasses.item = 'nps-button';
    options.cssClasses.itemText = 'nps-label';
  }
  if (options.question.rateMax === 5) {
    options.cssClasses.item = 'radio-button';
    options.cssClasses.itemText = 'radio-button-label';
  }
}

export function Survey (props: any) {
  const invitationToken: string = props?.match?.params?.id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});
  const [surveyConfig, setSurveyConfig] = useState<SurveyConfig>({});

  async function loadSurveyConfig (invitationToken: string) {
    setLoading(true);
    try {
      const loadSurveyResponse: SurveyConfig = await ResponseService.loadSurveyDef(invitationToken);
      setSurveyConfig(loadSurveyResponse);
    } catch (err) {
      alert(err?.message);
    } finally {
      setLoading(false);
    }
  }

  async function saveResponse (response: any) {
    if (!surveyConfig.answerId) return;
    try {
      return await ResponseService.saveResponse(surveyConfig.answerId, response);
    } catch (ex) {
      console.log('Ocurrió un error al guardar.', ex);
    }
  }

  async function onValidateQuestion (survey: any) {
    console.log('Survey', survey);
    console.log('Change', survey?.valuesHash);
    const updatedResponse = { ...response, ...survey?.valuesHash };
    setResponse(updatedResponse);
    await saveResponse(updatedResponse);
  }

  async function toThankyou (survey: any) {
    await onValidateQuestion(survey);
    return props?.history?.replace('/gracias')
  }

  useEffect(() => {
    loadSurveyConfig(invitationToken);
  }, []);

  if (loading || !surveyConfig.surveyDef) return null;

  return (
    <>
      <SurveyJS.Survey
        json={surveyConfig.surveyDef}
        css={surveyClasses}
        onUpdateQuestionCssClasses={setRatingClasses}
        onComplete={toThankyou}
        onPartialSend={onValidateQuestion}
      />
      <Link to="aviso-de-privacidad">
        Aviso de Privacidad
      </Link>
    </>
  )
}