import React from 'react';

export function Thanks () {
  return (
    <div className="message">
      <h2 style={{ color: '#293951' }}>¡Gracias!</h2>
      <p>Hemos recibido su opinión.</p>
      <p>Todo nuestro equipo trabajará para mejorar su experiencia.</p>
    </div>
  )
}

export function Welcome () {
  return (
    <div className="message">
      <h2 style={{ color: '#293951' }}>¡Gracias!</h2>
      <p>Gracias por su interés para darnos una opinión.</p>
      <p>Recibirá una invitación personalizada la próxima vez que interactúe con <b>Eidos</b>.</p>
    </div>
  )
}